const constRouters = [
	{
		path: '/findpwd'
	},
	{
		path: '/preview'
	},
	{
		path: '/reviewPage'
	},
	{
		path: '/callback'
	}
]
/**
 * 路由白名单（数组形式）
 *
 * 如果组件像登录一样，那就简单的写一个path，即可实现放开，
 * 如果组件不在这边的，需要手动添加组件，就像other一样，
 * 因为没登陆你没法拿到后端给你返回的那一坨，当然就找不到component
 *
 * @author yubaoshan
 */
export default constRouters
