import { createI18n } from 'vue-i18n'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import enGB from 'ant-design-vue/es/locale/en_GB'
import en from './lang/en.js'
import zh_cn from './lang/zh-cn.js'
import { APP_CONFIG } from '@/config/index.js'

export const messages = {
	'zh-cn': {
		lang: zhCN,
		...zh_cn
	},
	en: {
		lang: enGB,
		...en
	}
}

const i18n = createI18n({
	locale: APP_CONFIG.MTKF_LANG,
	fallbackLocale: 'zh-cn',
	globalInjection: true,
	messages
})

export default i18n
